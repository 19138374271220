<template>
  <div class="mt-n1">
    <v-btn
      :disabled="disabled"
      @click="executeRunSelected()"
      text
      id="executeRunSelected"
    >
      <v-icon left>mdi-play</v-icon>
    </v-btn>
    <startParametersDialog
      v-if="parameterDialog"
      v-model="startParameters"
      :parameterDialog="parameterDialog"
      :loading="loading"
      @confirmation-ok="runTest"
      :testNames="testNames"
    >
    </startParametersDialog>
    <confirmationDialog
      :hideCancelButton="true"
      :value="showErrorDialog"
      title="Error"
      message="Error Running test. Please retry later"
      @confirmation-ok="showErrorDialog = !showErrorDialog"
      maxWidth="290"
    ></confirmationDialog>
    <standbyDialog :localLoadingDialog="loadingOverlay"></standbyDialog>
  </div>
</template>
<script>
import startParametersDialog from "@/components/legacy/startParametersDialog.vue";
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
import standbyDialog from "@/commonComponents/standbyDialog.vue";
export default {
  props: ["result", "additional"],
  components: {
    startParametersDialog,
    confirmationDialog,
    standbyDialog,
  },
  data() {
    return {
      parameterDialog: false,
      startParameters: [],
      loading: false,
      showErrorDialog: false,
      loadingOverlay: false,
    };
  },
  computed: {
    disabled() {
      let disabled = false;
      if (Object.keys(this.additional.selectedRows).length == 0) {
        disabled = true;
      }
      return disabled;
    },
  },
  methods: {
    runTest(execute) {
      if (execute) {
        this.executeRunSelected();
      } else {
        this.parameterDialog = false;
      }
    },
    executeRunSelected() {
      let selected = [];
      Object.keys(this.additional.selectedRows).forEach(function eachKey(key) {
        selected.push(parseInt(key));
      });
      if (selected.length > 0) {
        this.$emit("show-warning", false);
        this.loadingOverlay = true;
        var options = {
          function: "executeSelectedTest",
          selected: selected,
          requestType: "ajax",
          startParameters: this.startParameters,
        };
        /* if (typeof groupID != "undefined") {
            options["groupID"] = groupID;
          }*/
        //const qs = require("querystring");
        let caller = this;
        this.frameworkAxiosRequest({
          method: 'POST',
          url: 'serve.php?f=testing&f2=testsExecuteApi',
          data: options,
        })
            .then((response) => {
            this.loadingOverlay = false;
            if (response.data.result.responseCode == "201") {
              // console.log(response.data.result.redirect);
              //this.$router.push(response.data.result.redirect);
              window.location.href = response.data.result.redirect;
              caller.parameterDialog = false;
            } else if (response.data.result.responseCode == "300") {
              caller.parameterDialog = true;
              // console.log(response.data.result.body);
              caller.startParameters =
                response.data.result.body.startParameters;
              caller.testNames = response.data.result.testNames;
            } else {
              caller.showErrorDialog = true;
              caller.parameterDialog = false;
              caller.startParameters = [];
            }
          })
          .catch(function (error) {
            console.log(error)
          });
      } else {
        this.$emit("show-warning", true);
      }
    },
  },
};
</script>